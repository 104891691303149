// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-article-js": () => import("../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-state-map-js": () => import("../src/templates/state-map.js" /* webpackChunkName: "component---src-templates-state-map-js" */),
  "component---src-templates-team-js": () => import("../src/templates/team.js" /* webpackChunkName: "component---src-templates-team-js" */),
  "component---src-templates-bat-js": () => import("../src/templates/bat.js" /* webpackChunkName: "component---src-templates-bat-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-articles-index-js": () => import("../src/pages/articles/index.js" /* webpackChunkName: "component---src-pages-articles-index-js" */),
  "component---src-pages-bats-index-js": () => import("../src/pages/bats/index.js" /* webpackChunkName: "component---src-pages-bats-index-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-manage-js": () => import("../src/pages/manage.js" /* webpackChunkName: "component---src-pages-manage-js" */),
  "component---src-pages-reset-js": () => import("../src/pages/reset.js" /* webpackChunkName: "component---src-pages-reset-js" */),
  "component---src-pages-softball-map-index-js": () => import("../src/pages/softball-map/index.js" /* webpackChunkName: "component---src-pages-softball-map-index-js" */)
}

